// @ts-check
/* eslint-disable react/prop-types */

import { useCallback, useEffect, useMemo, useState } from 'react';

import { StudioSourceSettingsContext } from './Context';
import { SourceParticipantOfferType, useSourceParticipantOffers } from '../SourceParticipantOffers/Context';
import { SOURCE_TYPE_PARTICIPANT } from '../../lib/stream';

/**
 * @import { IStudioSourceSettingsContext, StudioSourceSettings } from './Context';
 */

const LOCAL_STORAGE_STUDIO_SOURCE_SETTINGS_KEY = 'beeyou_studioSourceSettings';
const LOCAL_STORAGE_STUDIO_SOURCE_SETTINGS_VERSION = 1;

/**
 * @typedef {{
 *  studioSourceSettings: {
 * 		sourceSettings: IStudioSourceSettingsContext['sourceSettings'],
 * 	},
 *  __version: number,
 * }} LocalStorageStudioSourceSettings
 */

/**
 * @returns {LocalStorageStudioSourceSettings['studioSourceSettings'] | undefined}
 */
const getLocalStorageValue = () => {
	const value = localStorage.getItem(LOCAL_STORAGE_STUDIO_SOURCE_SETTINGS_KEY);
	if (!value) {
		return undefined;
	}
	try {
		/** @type {LocalStorageStudioSourceSettings} */
		const parsedValue = JSON.parse(value);
		// eslint-disable-next-line no-underscore-dangle
		if (parsedValue.__version !== LOCAL_STORAGE_STUDIO_SOURCE_SETTINGS_VERSION) {
			return undefined;
		}
		return parsedValue.studioSourceSettings;
	} catch (error) {
		console.error('Error parsing local storage value', error);
		return undefined;
	}
};

/**
 * @returns {LocalStorageStudioSourceSettings['studioSourceSettings']}
 */
const getInitialStudioSourceSettings = () => {
	const studioSourceSettings = getLocalStorageValue();
	return studioSourceSettings || { sourceSettings: [] };
};

/** @type {LocalStorageStudioSourceSettings['studioSourceSettings']} */
const initialStudioSourceSettings = getInitialStudioSourceSettings();

/**
 * @param {LocalStorageStudioSourceSettings['studioSourceSettings']} studioSourceSettings
 * @returns {void}
 */
const saveLocalStorageValue = (studioSourceSettings) => {
	localStorage.setItem(
		LOCAL_STORAGE_STUDIO_SOURCE_SETTINGS_KEY,
		JSON.stringify({
			studioSourceSettings,
			__version: LOCAL_STORAGE_STUDIO_SOURCE_SETTINGS_VERSION,
		}),
	);
};

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} StudioSourceSettingsProviderProps
 */

/**
 * @param {SourceParticipantOfferType} sourceOfferType
 * @returns {StudioSourceSettings['settings']}
 */
const getDefaultSourceSettingBySourceOfferType = (sourceOfferType) => {
	if (
		sourceOfferType === SourceParticipantOfferType.CONFIG
		|| sourceOfferType === SourceParticipantOfferType.SCREENSHARE
	) {
		return {
			monitor: false,
		};
	}
	return {
		monitor: true,
	};
};

export const StudioSourceSettingsProvider = (
	/** @type {StudioSourceSettingsProviderProps} */
	{ children },
) => {
	const [sourceSettings, setSourceSettings] = useState(
		/** @type {() => IStudioSourceSettingsContext['sourceSettings']} */(() => (
			initialStudioSourceSettings?.sourceSettings
		)),
	);

	const { sources: sourcePartipantOffers } = useSourceParticipantOffers();

	const getSettingsBySource = useCallback(
		/** @type {IStudioSourceSettingsContext['getSettingsBySource']} */
		(source) => {
			const sourceSetting = sourceSettings.find((srcSetting) => (
				srcSetting.id === source.id
				&& srcSetting.type === source.type
			));

			const sourceOffer = source.type === SOURCE_TYPE_PARTICIPANT
				? sourcePartipantOffers.find((src) => src.id === source.id)
				: undefined;

			/*
			 * If the source is one of our participant sources offers, then we return
			 * default settings for the source offer depending on its subType
			 * (screen | config | audioshare etc).
			 */
			if (sourceOffer) {
				return {
					id: source.id,
					type: source.type,
					settings: {
						...getDefaultSourceSettingBySourceOfferType(sourceOffer.subType),
						...sourceSetting?.settings,
					},
				};
			}

			return sourceSetting;
		},

		[sourcePartipantOffers, sourceSettings],
	);

	const updateSettingsBySource = useCallback(
		/** @type {IStudioSourceSettingsContext['updateSettingsBySource']} */
		(source, settings) => {
			setSourceSettings((prevSourceSettings) => {
				const sourceIndex = prevSourceSettings.findIndex((sourceSetting) => (
					sourceSetting.id === source.id
					&& sourceSetting.type === source.type
				));
				if (sourceIndex === -1) {
					return [
						...prevSourceSettings,
						{
							id: source.id,
							type: source.type,
							settings,
						},
					];
				}
				const sourceSetting = prevSourceSettings[sourceIndex];
				return [
					...prevSourceSettings.slice(0, sourceIndex),
					{
						...sourceSetting,
						settings: {
							...sourceSetting.settings,
							...settings,
						},
					},
					...prevSourceSettings.slice(sourceIndex + 1),
				];
			});
		},
		[],
	);

	useEffect(() => {
		if (initialStudioSourceSettings.sourceSettings !== sourceSettings) {
			saveLocalStorageValue({ sourceSettings });
		}
	}, [sourceSettings]);

	const value = useMemo(() => ({
		getSettingsBySource,
		sourceSettings,
		updateSettingsBySource,
	}), [
		getSettingsBySource,
		sourceSettings,
		updateSettingsBySource,
	]);

	console.log({ StudioSourceSettingsContext: value });

	return (
		<StudioSourceSettingsContext.Provider value={value}>
			{children}
		</StudioSourceSettingsContext.Provider>
	);
};
