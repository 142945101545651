import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { Path, getLink } from '../../RoutePath';
import { useFetchHomePageVod } from '../../api-hooks/home';
import { ChannelListHexagon } from '../../components/Channel/ChannelListHexagon/ChannelListHexagon';
import { ListLoading } from '../../components/Channel/ItemList/Loading';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { ChannelSlider } from '../../components/Channel/Slider/Slider';
import { VideoListSlider } from '../../components/Channel/VideoListSlider/VideoListSlider';
import ReallyPerfectScrollbar from '../../components/ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { useUserPreferences } from '../../components/UserPreferences/Context';
import { MyAccountModalTab, UserSettingsModal, useUserSettings } from '../../components/UserSettings/Context';
import { useAsyncErrorLog } from '../../lib/hooks';
import { Footer } from '../Footer/Footer';
import { SHOW_COMPLETE_PROFILE_MODAL_KEY } from '../../components/Signup/Form/Form';
import { useFetchDefaultOrganization } from '../../api-hooks/public/organization';
import { useProfile } from '../../components/Profile/ProfileContext';
import { NewUserBanner } from '../../components/NewUser/Banner';
import './Home.scss';

const MEET_THE_BEES_LIMIT = 50;
const ORGANIZATIONS_LIMIT = 50;
const VIDEOS_LIMIT = 16;

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';
const isComvision = import.meta.env.VITE_PROJECT === 'comvision';

const ViewHome = () => {
	const { isLoggedIn } = useAuthentication();

	const { t } = useTranslation();
	const matchSuggested = useMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'suggested' }),
		end: true,
		caseSensitive: true,
	});
	const matchFollowed = useMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'followed' }),
		end: true,
		caseSensitive: true,
	});
	const matchTop = useMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'watch-videos' }),
		end: true,
		caseSensitive: true,
	});
	const matchNewContent = useMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'new-content' }),
		end: true,
		caseSensitive: true,
	});
	const matchCominUpLive = useMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'coming-up-live' }),
		end: true,
		caseSensitive: true,
	});
	const matchMeetTheBees = useMatch({
		path: getLink(Path.HOME_ANCHOR, { anchor: 'meet-the-bees' }),
		end: true,
		caseSensitive: true,
	});

	const followedRef = useRef();
	const scrollbar = useRef();
	const suggestedRef = useRef();
	const topRef = useRef();
	const newContentRef = useRef();
	const comingUpLiveRef = useRef();
	const meetTheBeesRef = useRef();
	const organizationsRef = useRef();

	const {
		data: vodData,
		error: homePageVodError,
		refetch: fetchHomePageVod,
		isLoading,
	} = useFetchHomePageVod();
	useAsyncErrorLog({ error: homePageVodError });

	const { openModal } = useUserSettings();

	const { getUserPreference, setUserPreferences } = useUserPreferences();

	const { data: defaultOrganization } = useFetchDefaultOrganization();
	const { profile } = useProfile();

	const showOrganizations = useMemo(() => (
		profile && defaultOrganization
			? profile.organization._id === defaultOrganization._id
			: true
	), [defaultOrganization, profile]);

	const showFeaturedRoles = useMemo(() => (
		!!profile && !!defaultOrganization && profile.organization !== defaultOrganization._id
	), [defaultOrganization, profile]);

	const showUserProfileModal = useMemo(
		() => getUserPreference(SHOW_COMPLETE_PROFILE_MODAL_KEY),
		[getUserPreference],
	);

	useEffect(() => {
		if (showUserProfileModal) {
			openModal(UserSettingsModal.ACCOUNT, MyAccountModalTab.DETAILS);
			setUserPreferences(
				(s) => ({ ...s, [SHOW_COMPLETE_PROFILE_MODAL_KEY]: false }),
			);
		}
	}, [openModal, showUserProfileModal, setUserPreferences]);

	useEffect(() => {
		if (matchFollowed && followedRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (followedRef.current.offsetTop - 100);
		}
		if (matchSuggested && suggestedRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (suggestedRef.current.offsetTop - 100);
		}
		if (matchTop && topRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (topRef.current.offsetTop - 80);
		}
		if (matchNewContent && newContentRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (newContentRef.current.offsetTop - 100);
		}
		if (matchCominUpLive && comingUpLiveRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (comingUpLiveRef.current.offsetTop - 100);
		}
		if (matchMeetTheBees && meetTheBeesRef.current?.offsetTop) {
			scrollbar.current.scrollTop = (meetTheBeesRef.current.offsetTop - 100);
		}
	});

	return (
		<div className="ViewHome app-inner-content-layout--main">
			<ReallyPerfectScrollbar ref={scrollbar}>
				<div className="app-content--inner--spacing p-0 overflow-hidden pt-4">{/* overflow-hidden because of slider next-stick */}
					{!isLoggedIn ? <NewUserBanner /> : null}
					{(vodData?.live?.length > 0) && (
						<ChannelSlider
							className="mw-100"
							forwardedRef={comingUpLiveRef}
							refetch={fetchHomePageVod}
							title={t('Home.comingUpLive')}
							lives={vodData?.live}
						/>
					)}
					{isComvision && vodData?.suggested?.length > 0 && (
						<VideoListSlider
							videos={vodData.suggested}
							forwardedRef={suggestedRef}
							title={t('Home.suggested')}
						/>
					)}
					{showFeaturedRoles && vodData?.featuredRoles
						? vodData.featuredRoles.map((featuredRole) => (
							<ChannelListHexagon
								channels={featuredRole.channels}
								forwardedRef={meetTheBeesRef}
								placeholderLength={MEET_THE_BEES_LIMIT}
								title={featuredRole.name}
							/>
						)) : (
							<ChannelListHexagon
								channels={vodData?.channels}
								forwardedRef={meetTheBeesRef}
								placeholderLength={MEET_THE_BEES_LIMIT}
								title={t('Home.discover')}
							/>
						)}
					{isBeeyou && (
						<>
							{vodData?.top?.length > 0 && (
								<VideoListSlider
									videos={vodData.top}
									forwardedRef={topRef}
									placeholderLength={VIDEOS_LIMIT}
									title={t('Home.top')}
								/>
							)}
							{vodData?.newVods?.length > 0 && (
								<VideoListSlider
									videos={vodData.newVods}
									forwardedRef={newContentRef}
									placeholderLength={VIDEOS_LIMIT}
									title={t('Home.newContent')}
								/>
							)}
						</>
					)}
					{showOrganizations && isBeeyou && vodData?.organizationChannels?.length > 0 && (
						<ChannelListHexagon
							channels={vodData?.organizationChannels}
							forwardedRef={organizationsRef}
							placeholderLength={ORGANIZATIONS_LIMIT}
							title={t('Home.organizations')}
							isOrganizationChannel
						/>
					)}
					{isBeeyou && vodData?.followed?.length > 0 && (
						<VideoListSlider
							videos={vodData.followed}
							forwardedRef={followedRef}
							title={t('Home.followed')}
							className="mt-4"
						/>
					)}
					{isBeeyou && vodData?.suggested?.length > 0 && (
						<VideoListSlider
							videos={vodData.suggested}
							forwardedRef={suggestedRef}
							title={t('Home.suggested')}
						/>
					)}
					{isLoading && <ListLoading />}
					<Footer />
				</div>
			</ReallyPerfectScrollbar>
		</div>
	);
};

ViewHome.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			tags: PropTypes.string,
		}),
	}),
};

ViewHome.defaultProps = {
	match: null,
};

export default memo(ViewHome);
