// @ts-check

import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';

import { Resolution, stopTrack, MediaShareVideoType } from '../utils';
import { getComputerId } from '../../../lib/computerId';
import { SourceParticipantOfferType, useSourceParticipantOffers } from '../../SourceParticipantOffers/Context';

/**
 * @import {
 * 	SourceParticipantOfferScreenDeviceKind,
 *  SourceParticipantOfferTrack,
 * } from '../../SourceParticipantOffers/Context';
 */

/**
 * @param {Resolution} res
 * @returns {MediaTrackConstraints}
 */
const getDisplayMediaVideoConstraints = (res) => ({
	height: { max: res },
	// ideal is not supported by safari 16+ :
	// https://bugs.webkit.org/show_bug.cgi?id=247310
	width: { max: Math.round(res * (16 / 9)) },
	frameRate: { max: 5 },
});

const DEFAULT_CONFIG_ID = 0;

/**
 * @typedef {SourceParticipantOfferTrack<
 * 	typeof SourceParticipantOfferType.SCREENSHARE>} MediaStreamTrackScreenshare
 */

/**
 * @typedef {MediaStream & {
 *  configId: number,
 * }} MediaStreamScreenshare
 */

/**
 * @typedef {{
 * 	requestScreenshare: () => Promise<void>,
 * 	screenshareActive: boolean,
 * 	screenshareActiveTracks: MediaStreamTrackScreenshare[],
 * 	screenshareMediastream: MediaStreamScreenshare | undefined,
 * 	screenshareRequestError: any,
 * 	stopScreenshare: () => void,
 * 	toggleScreenshare: () => void,
 * }} MediaShareScreenContextValue
 */

const MediaShareScreenContext = createContext(/** @type {MediaShareScreenContextValue} */({}));

export const useMediaShareScreen = () => useContext(MediaShareScreenContext);

/**
 * @param {MediaStreamTrack} track
 * @returns {SourceParticipantOfferScreenDeviceKind}
 */
const getScreenKindFromTrack = (track) => {
	if (track.kind === 'audio') return 'audioscreen';
	if (track.kind === 'video') return 'videoscreen';
	throw new Error(`Unknown kind '${track.kind}'`);
};

/**
 * @typedef {{
 * 	activeShareType: MediaShareVideoType?,
 * 	children: React.ReactNode,
 * 	disabled: boolean,
 * 	isHost: boolean,
 * 	onShare: (type: MediaShareVideoType) => void,
 * 	resolution: Resolution,
 * }} MediaShareScreenProps
 */

export const MediaShareScreen = (
	/** @type {MediaShareScreenProps} */
	{
		activeShareType,
		children,
		disabled,
		isHost,
		onShare,
		resolution,
	},
) => {
	const { createOrUpdateSource, removeSourceById } = useSourceParticipantOffers();
	const [screenshareActiveTracks, setScreenshareActiveTracks] = useState(
		/** @type {MediaShareScreenContextValue['screenshareActiveTracks']} */([]),
	);
	const [screenshareRequestError, setScreenshareRequestError] = useState(
		/** @type {MediaShareScreenContextValue['screenshareRequestError']} */(undefined),
	);

	const screenshareActive = screenshareActiveTracks.length > 0;

	/** @type {MediaStreamScreenshare | undefined} */
	const screenshareMediastream = useMemo(() => {
		if (screenshareActiveTracks.length > 0) {
			const mediastream = /** @type {MediaStreamScreenshare} */(
				new MediaStream(screenshareActiveTracks)
			);
			mediastream.configId = DEFAULT_CONFIG_ID;
			// Refresh mediastream when tracks change to avoid player image stuck
			return mediastream;
		}
		return undefined;
	}, [screenshareActiveTracks]);

	const resolutionRef = useRef(resolution);
	useEffect(() => { resolutionRef.current = resolution; }, [resolution]);

	const isAllowed = (isHost || activeShareType === MediaShareVideoType.SCREEN);

	const isScreenshareRequested = useRef(false);

	const requestScreenshare = useCallback(async () => {
		isScreenshareRequested.current = true;

		try {
			setScreenshareRequestError(undefined);

			const mediastream = await navigator.mediaDevices.getDisplayMedia({
				audio: true,
				video: getDisplayMediaVideoConstraints(resolutionRef.current),
			});

			// Allow cancellation
			const isStillScreenshareRequested = isScreenshareRequested.current;

			const sourceOffer = {
				computerId: getComputerId(),
				configId: 0,
				id: `${getComputerId()}:0:screen`,
				label: 'Screenshare',
				subType: SourceParticipantOfferType.SCREENSHARE,
			};

			// Possible cancellation while getUserMedia was pending
			const tracks = /** @type {MediaStreamTrackScreenshare[]} */(mediastream.getTracks());
			tracks.forEach((track) => {
				track.configId = DEFAULT_CONFIG_ID;
				track.device = {
					deviceId: `${getComputerId()}:screen`,
					kind: getScreenKindFromTrack(track),
					label: `Screenshare ${track.kind}`,
				};
				track.sourceOffer = sourceOffer;
			});
			if (!isStillScreenshareRequested) tracks.forEach((track) => track.stop());
			else {
				createOrUpdateSource({
					...sourceOffer,
					devices: tracks.map((track) => track.device),
				});

				setScreenshareActiveTracks((state) => [...state, ...tracks]);
				onShare(MediaShareVideoType.SCREEN);
			}
		} catch (error) {
			console.error(error);
			setScreenshareRequestError(error);
		}
	}, [createOrUpdateSource, onShare]);

	useEffect(() => {
		/**
		 * @this {MediaStreamTrackScreenshare}
		 * @returns {void}
		 */
		function handleTrackEnded() {
			const track = this;
			track.removeEventListener('trackended', handleTrackEnded);
			setScreenshareActiveTracks((state) => state.filter((t) => t !== track));
		}

		screenshareActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			screenshareActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [screenshareActiveTracks]);

	const screenshareActiveTracksRef = useRef(screenshareActiveTracks);
	screenshareActiveTracksRef.current = screenshareActiveTracks;

	const stopScreenshare = useCallback(() => {
		isScreenshareRequested.current = false;
		screenshareActiveTracksRef.current.forEach(stopTrack);
		setScreenshareActiveTracks((s) => (s.length < 1 ? s : []));
		setScreenshareRequestError(undefined);
		removeSourceById(`${getComputerId()}:0:screen`);
	}, [removeSourceById]);

	useEffect(() => {
		// If screenshare is stopped from system button, disable screenshare
		if (screenshareActiveTracks.length < 1) stopScreenshare();
	}, [screenshareActiveTracks, stopScreenshare]);

	useEffect(() => {
		const shouldStopScreenshare = isScreenshareRequested.current && (disabled || !isAllowed);
		if (shouldStopScreenshare) stopScreenshare();
	}, [disabled, isAllowed, stopScreenshare]);

	// cleanup
	useEffect(() => () => {
		isScreenshareRequested.current = false;
		screenshareActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const toggleScreenshare = useCallback(() => {
		if (screenshareActive) stopScreenshare();
		else requestScreenshare();
	}, [requestScreenshare, screenshareActive, stopScreenshare]);

	const value = useMemo(() => ({
		requestScreenshare,
		screenshareActive,
		screenshareActiveTracks,
		screenshareMediastream,
		screenshareRequestError,
		stopScreenshare,
		toggleScreenshare,
	}), [
		requestScreenshare,
		screenshareActive,
		screenshareActiveTracks,
		screenshareMediastream,
		screenshareRequestError,
		stopScreenshare,
		toggleScreenshare,
	]);

	return (
		<MediaShareScreenContext.Provider value={value}>
			{children}
		</MediaShareScreenContext.Provider>
	);
};

MediaShareScreen.propTypes = {
	activeShareType: PropTypes.oneOf(Object.values(MediaShareVideoType)).isRequired,
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	isHost: PropTypes.bool,
	onShare: PropTypes.func.isRequired,
	resolution: PropTypes.oneOf(Object.values(Resolution)),
};

MediaShareScreen.defaultProps = {
	disabled: false,
	isHost: false,
	resolution: Resolution.P720,
};
