//@ts-check

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import * as imagesApi from '../../api/channel/images';
import * as publicApiImages from '../../api/public/images';
import { upload } from '../../api/upload';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import { GFX_QUERY_KEYS } from './gfx';
import { CHANNEL_FEED_QUERY_KEYS, FEED_ITEMS_PER_PAGE } from './feed';
import { useProfile } from '../../components/Profile/ProfileContext';

const ITEMS_PER_PAGE = 20;

export const IMAGE_QUERY_KEYS = {
	fetchImages: (channelId, mode, itemsPerPage, currentPage, folderId) => ['api', 'channel', 'images', 'fetch', { channelId, mode, itemsPerPage, currentPage, folderId }],
	fetchImagesBase: () => ['api', 'channel', 'images', 'fetch'],
};

export const useFetchImages = (
	channelId,
	mode,
	itemsPerPage,
	currentPage,
	folderId,
) => useQuery(
	IMAGE_QUERY_KEYS.fetchImages(channelId, mode, itemsPerPage, currentPage, folderId),
	axiosQueryWrapper(imagesApi.fetchImages, channelId, mode, itemsPerPage, currentPage, folderId),
);

/**
 * @param {string} [channelId]
 */

export const useFetchAllPublicImages = (channelId) => useQuery(
	IMAGE_QUERY_KEYS.fetchImages(channelId),
	axiosQueryWrapper(publicApiImages.fetchAllPublicImagesByChannelId, channelId),
);

export const useInfinityFetchImages = (
	channelId,
	mode,
	itemsPerPage = ITEMS_PER_PAGE,
	folderId,
) => useInfiniteQuery(
	IMAGE_QUERY_KEYS.fetchImages(channelId, mode, itemsPerPage, undefined, folderId),
	({ pageParam = 1 }) => axiosQueryWrapper(
		imagesApi.fetchImages,
		channelId,
		mode,
		itemsPerPage,
		pageParam,
		folderId,
	)(),
	{
		getNextPageParam: (lastPage, allPages) => {
			const currentItemsLenght = allPages.reduce((acc, curr) => acc + curr.data.length, 0);
			if (currentItemsLenght < lastPage.totalItemsCount) {
				return allPages.length + 1;
			}
			return undefined;
		},
	},
);

export const useUploadImage = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		async ({ channelId, image, onUploadProgress }) => {
			const { blob } = image;
			const filename = await upload(blob, onUploadProgress);
			if (!filename || filename.error) throw filename;

			const imageToCreate = {
				isGfx: image.isGfx,
				label: image.label,
				isShared: image.isShared || undefined,
				mimeType: blob.type,
				filename,
				isPublic: image.isPublic,
				fileSize: blob.size,
				layerConfig: image.layerConfig,
				folder: image.folderId,
			};

			return axiosMutationWrapper(imagesApi.createImage)({
				channelId,
				image: imageToCreate,
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(IMAGE_QUERY_KEYS.fetchImagesBase());
				queryClient.invalidateQueries(GFX_QUERY_KEYS.fetchGfx());
				queryClient.invalidateQueries(CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(
					profile?._id, FEED_ITEMS_PER_PAGE,
				));
			},
		},
	);
};

export const useUpdateImage = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		({ _id, label, isPublic, layerConfig }) => (
			axiosMutationWrapper(imagesApi.updateImage)({ _id, label, isPublic, layerConfig })
		),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(IMAGE_QUERY_KEYS.fetchImagesBase());
				queryClient.invalidateQueries(GFX_QUERY_KEYS.fetchGfx());
				queryClient.invalidateQueries(CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(
					profile?._id, FEED_ITEMS_PER_PAGE,
				));
			},
		},
	);
};

export const useDeleteImage = () => {
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	return useMutation(
		axiosMutationWrapper(imagesApi.deleteImage),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(IMAGE_QUERY_KEYS.fetchImagesBase());
				queryClient.invalidateQueries(GFX_QUERY_KEYS.fetchGfx());
				queryClient.invalidateQueries(CHANNEL_FEED_QUERY_KEYS.infinityFetchChannelFeed(
					profile?._id, FEED_ITEMS_PER_PAGE,
				));
			},
		},
	);
};
