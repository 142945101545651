// @ts-check
/* eslint-disable react/prop-types */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { SourceParticipantOffersContext, SourceParticipantOfferType } from './Context';
import { useInputs } from '../Inputs';
import { getComputerId } from '../../lib/computerId';

/**
 * @import {
 * 	ISourceParticipantOffersContext,
 * 	SourceParticipantOfferMediaDeviceKind,
 * } from './Context';
 * @import { VirtualDevice } from '../Inputs/Context';
 */

/**
 * @typedef {{
 *  children: import('react').ReactNode
 * }} SourceParticipantOffersProviderProps
 */

export const SourceParticipantOffersProvider = (
	/** @type {SourceParticipantOffersProviderProps} */
	{ children },
) => {
	const [sources, setSources] = useState(
		/** @type {ISourceParticipantOffersContext['sources']} */([]),
	);

	const removeSourceById = useCallback(
		/** @type {ISourceParticipantOffersContext['removeSourceById']} */
		(sourceId) => {
			setSources((prev) => prev.filter(({ id }) => id !== sourceId));
		}, [],
	);

	const removeSourceByType = useCallback(
		/** @type {ISourceParticipantOffersContext['removeSourceById']} */
		(sourceParticipantType) => {
			setSources((prev) => prev.filter(({ subType }) => subType !== sourceParticipantType));
		}, [],
	);

	const createOrUpdateSource = useCallback(
		/**
		 * @type {ISourceParticipantOffersContext['createOrUpdateSource']}
		 */
		(source) => {
			setSources((prev) => {
				const src = prev.find(({ id }) => (id === source.id));
				if (!src) {
					return [...prev, source];
				}
				return prev.map((s) => (s.id === source.id ? ({
					...s,
					...source,
				}) : s));
			});
		}, [],
	);

	const {
		inputDevices,
		inputsConfig,
	} = useInputs();

	useEffect(() => {
		// TODO: better setState to avoid re-renders
		removeSourceByType(SourceParticipantOfferType.CONFIG);
		/**
		 * @param {string} virtualDeviceId
		 * @returns {VirtualDevice | undefined}
		 */
		const getInputDeviceById = (virtualDeviceId) => (
			inputDevices.find((device) => device.virtualDeviceId === virtualDeviceId)
		);
		inputsConfig.forEach((inputConfig) => {
			const devices = [
				(inputConfig.audioInputId && inputConfig.audioInputId !== 'none')
					? {
						deviceId: inputConfig.audioInputId,
						disabled: inputConfig.audioInputOff,
						kind: /** @type {SourceParticipantOfferMediaDeviceKind} */('audioinput'),
						label: getInputDeviceById(inputConfig.audioInputId)?.label || '',
					}
					: undefined,
				(inputConfig.videoInputId && inputConfig.videoInputId !== 'none')
					? {
						deviceId: inputConfig.videoInputId,
						disabled: inputConfig.videoInputOff,
						kind: /** @type {SourceParticipantOfferMediaDeviceKind} */('videoinput'),
						label: getInputDeviceById(inputConfig.videoInputId)?.label || '',
					}
					: undefined,
			].filter((device) => !!device);

			const id = `${getComputerId()}:${inputConfig.id}:${SourceParticipantOfferType.CONFIG}`;

			const source = {
				computerId: getComputerId(),
				configId: inputConfig.id,
				id,
				label: inputConfig.label,
				subType: SourceParticipantOfferType.CONFIG,
				devices,
			};

			createOrUpdateSource(source);
		});
	}, [
		createOrUpdateSource,
		inputsConfig,
		inputDevices,
		removeSourceByType,
	]);

	const value = useMemo(() => ({
		createOrUpdateSource,
		removeSourceById,
		sources,
	}), [
		createOrUpdateSource,
		removeSourceById,
		sources,
	]);

	console.log({
		SourceParticipantOffersProvider: value,
	});

	return (
		<SourceParticipantOffersContext.Provider value={value}>
			{children}
		</SourceParticipantOffersContext.Provider>
	);
};
