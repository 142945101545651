/* eslint-disable react/prop-types */
// @ts-check

import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useMediaUser } from '../User';

/** @import { MediaStreamTrackUser, MediaStreamUser } from '../User'; */

/**
 * @typedef {MediaStreamTrackUser & {
 *   isCrop: true
 * }} MediaStreamTrackCrop
 */

/**
 * @typedef {MediaStreamTrackUser | MediaStreamTrackCrop} MediaStreamTrackUserOrCrop
 */

/**
 * @typedef {{
 *  factor: number,
 * }} PersonalCrop
 */

/**
 * @typedef {{
 * addCropVideoTrack: (track: MediaStreamTrackCrop) => void,
 * cropOrUserVideoActiveTracks: MediaStreamTrackUserOrCrop[],
 * cropOrUserActiveTracks: MediaStreamTrackUserOrCrop[],
 * cropOrUserMediastreams: MediaStreamUser[],
 * cropVideoActiveTracks: MediaStreamTrackCrop[],
 * isCropEnabled: boolean,
 * removeCropVideoTrackByTrack: (track: MediaStreamTrackCrop) => void,
 * personalCrop: PersonalCrop | undefined,
 * setPersonalCrop: React.Dispatch<React.SetStateAction<PersonalCrop | undefined>>,
 * }} IMediaCropContext
 */

export const MediaCropContext = createContext(/** @type {IMediaCropContext}*/({}));
export const useMediaCrop = () => useContext(MediaCropContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} MediaCropProviderProps
 */

export const MediaCropProvider = (
	/** @type {MediaCropProviderProps} */
	{ children },
) => {
	// TODO : type personalCrop
	const [personalCrop, setPersonalCrop] = useState(
		/** @type {PersonalCrop | undefined} */(undefined),
	);
	const [cropVideoActiveTracks, setCropVideoActiveTracks] = useState(
		/** @type {IMediaCropContext['cropVideoActiveTracks']} */([]),
	);
	const { userVideoActiveTracks, userMediastreams, userAudioActiveTracks } = useMediaUser();

	/** @type {IMediaCropContext['addCropVideoTrack']} */
	const addCropVideoTrack = useCallback(
		(track) => { setCropVideoActiveTracks((prev) => [...prev, track]); },
		[],
	);

	/** @type {IMediaCropContext['removeCropVideoTrackByTrack']} */
	const removeCropVideoTrackByTrack = useCallback((track) => {
		setCropVideoActiveTracks((prev) => prev.filter(({ configId }) => configId !== track.configId));
	}, []);

	const isCropEnabled = !!personalCrop;

	const cropOrUserVideoActiveTracks = useMemo(() => {
		// ensure user track is removed even if key track is not yet available
		// in order to avoid publish two tracks in a row
		if (isCropEnabled) {
			return [
				...cropVideoActiveTracks,
				...userVideoActiveTracks.filter(({ configId }) => configId !== 0),
			];
		}
		return userVideoActiveTracks;
	}, [
		cropVideoActiveTracks,
		isCropEnabled,
		userVideoActiveTracks,
	]);

	const cropOrUserActiveTracks = useMemo(
		() => [
			...cropOrUserVideoActiveTracks,
			...userAudioActiveTracks,
		], [cropOrUserVideoActiveTracks, userAudioActiveTracks],
	);

	const cropOrUserMediastreams = useMemo(() => {
		if (cropVideoActiveTracks?.length > 0) {
			const cropMediaStream = /** @type {MediaStreamUser} */(new MediaStream());
			cropMediaStream.configId = 0;
			cropMediaStream.addTrack(cropVideoActiveTracks[0]);
			const userMediaStream = userMediastreams.find(({ configId }) => configId === 0);
			if (userMediaStream) {
				const track = userMediaStream.getAudioTracks()[0];
				if (track) cropMediaStream.addTrack(track);
			}

			// Refresh cropMediaStream when tracks change to avoid player image stuck
			return [
				...userMediastreams.filter(({ configId }) => configId !== 0),
				cropMediaStream,
			];
		}
		return userMediastreams;
	}, [cropVideoActiveTracks, userMediastreams]);

	const contextValue = useMemo(() => ({
		addCropVideoTrack,
		cropOrUserVideoActiveTracks,
		cropOrUserActiveTracks,
		cropOrUserMediastreams,
		cropVideoActiveTracks,
		isCropEnabled,
		removeCropVideoTrackByTrack,
		personalCrop,
		setPersonalCrop,
	}), [
		addCropVideoTrack,
		cropOrUserVideoActiveTracks,
		cropOrUserActiveTracks,
		cropOrUserMediastreams,
		cropVideoActiveTracks,
		isCropEnabled,
		removeCropVideoTrackByTrack,
		personalCrop,
		setPersonalCrop,
	]);

	return (
		<MediaCropContext.Provider value={contextValue}>
			{children}
		</MediaCropContext.Provider>
	);
};
