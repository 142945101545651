// @ts-check

import { useCallback, useMemo } from 'react';

import { useReactVideo } from '../components/ReactVideo/Provider';
import { useMediaTalkback } from '../components/Media/Talkback/Talkback';
import { usePublications } from '../components/Soup/hooks';
import { getComputerId } from './computerId';

/**
 * @import { Publication } from '../components/Soup/Context';
 */

/**
 * @typedef {Publication & {
 *  appData: Publication['appData'] & {
 * 		talkback: Exclude<Publication['appData']['talkback'], undefined>;
 * 	},
 * }} PublicationTalkback
 */

const useTalkbackSources = () => {
	const publications = usePublications();
	return /** @type {PublicationTalkback[]} */(
		useMemo(() => publications.filter(({ appData }) => !!appData.talkback), [publications])
	);
};

/**
 * @returns {{
 * 	talkbackReceivingSources: PublicationTalkback[],
 * 	talkbackSendingSources: PublicationTalkback[],
 * }}
 */
export const useTalkbackReceiver = () => {
	const { user } = useReactVideo();
	const talkbackSources = useTalkbackSources();

	const talkbackReceivingSources = useMemo(
		() => (talkbackSources || []).filter(
			({ appData }) => (
				appData.computerId !== getComputerId()
				&& appData.talkback?.receiverUserIds?.includes(user.sub)
			),
		), [talkbackSources, user],
	);
	const talkbackSendingSources = useMemo(
		() => (talkbackSources || []).filter(
			({ appData }) => appData.computerId === getComputerId(),
		), [talkbackSources],
	);

	return useMemo(() => ({
		talkbackReceivingSources,
		talkbackSendingSources,
	}), [
		talkbackReceivingSources,
		talkbackSendingSources,
	]);
};

export const useTalkbackSender = () => {
	const {
		configId,
		isDisconnecting,
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		userAudioRequestError,
	} = useMediaTalkback();

	return useMemo(() => ({
		configId,
		isDisconnecting,
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		userAudioRequestError,
	}), [
		configId,
		isDisconnecting,
		recipientUsers,
		startTalkbacks,
		stopAllTalkbacks,
		stopTalkbacks,
		userAudioRequestError,
	]);
};

/** @enum {string} */
export const TalkbackStatus = {
	READY: 'READY',
	CONNECTING: 'CONNECTING',
	CONNECTED: 'CONNECTED',
	DISCONNECTING: 'DISCONNECTING',
};

/**
* @returns {{
* 	senderStatus: TalkbackStatus,
* 	receiverStatus: TalkbackStatus,
* }}
*/
export const useTalkbackStatus = () => {
	const {
		isDisconnecting: isSenderDisconnecting,
		recipientUsers,
	} = useMediaTalkback();

	const {
		talkbackSendingSources,
		talkbackReceivingSources,
	} = useTalkbackReceiver();

	const isSenderActive = recipientUsers.length > 0;
	const isSenderConnected = talkbackSendingSources.length > 0;
	const isSenderConnecting = isSenderActive && !isSenderConnected;

	const getSenderStatus = useCallback(() => {
		if (isSenderDisconnecting) return TalkbackStatus.DISCONNECTING;
		if (isSenderConnecting) return TalkbackStatus.CONNECTING;
		if (isSenderConnected) return TalkbackStatus.CONNECTED;
		return TalkbackStatus.READY;
	}, [
		isSenderConnecting,
		isSenderConnected,
		isSenderDisconnecting,
	]);

	const getReceiverStatus = useCallback(() => {
		if (talkbackReceivingSources.length > 0) return TalkbackStatus.CONNECTED;
		return TalkbackStatus.READY;
	}, [talkbackReceivingSources]);

	return useMemo(() => ({
		receiverStatus: getReceiverStatus(),
		senderStatus: getSenderStatus(),
	}), [
		getReceiverStatus,
		getSenderStatus,
	]);
};
