// @ts-check

import { v4 as uuid } from 'uuid';

/**
 * @import { InputDeviceInfo, VirtualDevice } from './Context';
 */

const LOCAL_STORAGE_VIRTUAL_DEVICES_KEY = 'beeyou_virtualDevices';
const LOCAL_STORAGE_VIRTUAL_DEVICES_VERSION = 1;

/**
 * @typedef {{
 *  virtualDevices: VirtualDevice[],
 *  __version: number,
 * }} LocalStorageVirtualDevices
 */

/**
 * @returns {VirtualDevice[] | undefined}
 */
const getLocalStorageValue = () => {
	const value = localStorage.getItem(LOCAL_STORAGE_VIRTUAL_DEVICES_KEY);
	if (!value) {
		return undefined;
	}
	try {
		/** @type {LocalStorageVirtualDevices} */
		const parsedValue = JSON.parse(value);
		// eslint-disable-next-line no-underscore-dangle
		if (parsedValue.__version !== LOCAL_STORAGE_VIRTUAL_DEVICES_VERSION) {
			return undefined;
		}
		return parsedValue.virtualDevices;
	} catch (error) {
		console.error('Error parsing local storage value', error);
		return undefined;
	}
};

/**
 * @param {VirtualDevice[]} virtualDevices
 * @returns {void}
 */
const saveLocalStorageValue = (virtualDevices) => {
	localStorage.setItem(
		LOCAL_STORAGE_VIRTUAL_DEVICES_KEY,
		JSON.stringify({
			virtualDevices,
			__version: LOCAL_STORAGE_VIRTUAL_DEVICES_VERSION,
		}),
	);
};

/**
 * @returns {VirtualDevice[]}
 */
const createInitialVirtualDevices = () => [
	{
		isDefault: true,
		kind: /** @type {MediaDeviceKind}*/('videoinput'),
		label: 'Default Camera',
		physicalDeviceId: undefined,
		virtualDeviceId: uuid(),
	},
	{
		isDefault: true,
		kind: /** @type {MediaDeviceKind}*/('audioinput'),
		label: 'Default Microphone',
		physicalDeviceId: undefined,
		virtualDeviceId: uuid(),
	},
];

/**
 * @returns {VirtualDevice[]}
 */
const initializeVirtualDevices = () => {
	/** @type {VirtualDevice[] | undefined} */
	let initialVirtualDevices = getLocalStorageValue();
	if (!initialVirtualDevices) {
		initialVirtualDevices = createInitialVirtualDevices();
		saveLocalStorageValue(initialVirtualDevices);
	}
	return initialVirtualDevices;
};

/** @type {VirtualDevice[]} */
let virtualDevices = initializeVirtualDevices();

/**
 * @typedef {{
 * 	label: string,
 * 	kind: MediaDeviceKind,
 * }} DeviceIdentifier
 */

/**
 * Compare devices by label because device ids
 * change on page reload on safari.
 * @param {DeviceIdentifier} a
 * @param {DeviceIdentifier} b
 * @returns {boolean}
 */
const devicesEqual = (a, b) => (
	a.label === b.label
	&& a.kind === b.kind
);

/**
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
const createVirtualDevice = (physicalDevice) => {
	if (!physicalDevice.deviceId) {
		throw new Error('Cannot create virtual device without physical device ID');
	}

	const virtualDevice = {
		kind: physicalDevice.kind,
		isBack: physicalDevice.isBack,
		isDefault: physicalDevice.isDefault,
		isFront: physicalDevice.isFront,
		label: physicalDevice.label,
		physicalDeviceId: physicalDevice.deviceId || undefined,
		virtualDeviceId: uuid(),
	};

	virtualDevices = [...virtualDevices, virtualDevice];
	saveLocalStorageValue(virtualDevices);

	return virtualDevice;
};

/**
 * @param {VirtualDevice} virtualDevice
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
const updateVirtualDevice = (virtualDevice, physicalDevice) => {
	virtualDevice = {
		...virtualDevice,
		isBack: physicalDevice.isBack,
		// Don't update isDefault because in case the isDefault
		// value is false (when the permission are not granted)
		// then it will override the isDefault value of the default virtual devices.
		// See comment in updateDefaultVirtualDevice function.
		// isDefault: physicalDevice.isDefault,
		isFront: physicalDevice.isFront,
		label: physicalDevice.label,
		physicalDeviceId: physicalDevice.deviceId || undefined,
	};

	virtualDevices = virtualDevices.map((vd) => (
		devicesEqual(vd, virtualDevice)
			? virtualDevice
			: vd
	));
	saveLocalStorageValue(virtualDevices);

	return virtualDevice;
};

/**
 * @returns {VirtualDevice[]}
 */
export const getVirtualDevices = () => virtualDevices;

/**
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
export const createOrUpdateVirtualDevice = (physicalDevice) => {
	let virtualDevice = virtualDevices.find((vd) => devicesEqual(vd, physicalDevice));

	if (!virtualDevice) {
		virtualDevice = createVirtualDevice(physicalDevice);
	} else {
		virtualDevice = updateVirtualDevice(virtualDevice, physicalDevice);
	}

	return virtualDevice;
};

/**
 * !! There can be only one default virtual device of each kind.
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
export const updateDefaultVirtualDevice = (physicalDevice) => {
	if (!physicalDevice.isDefault) {
		throw new Error('Cannot update default virtual device with non-default physical device');
	}

	let virtualDevice = virtualDevices.find((vd) => (
		vd.isDefault
		&& physicalDevice.kind === vd.kind
	));

	if (!virtualDevice) {
		throw new Error(`Cannot find default virtual device of kind ${physicalDevice.kind}`);
	}

	virtualDevice = {
		...virtualDevice,
		isBack: physicalDevice.isBack,
		isFront: physicalDevice.isFront,
		label: physicalDevice.label,
		physicalDeviceId: physicalDevice.deviceId || undefined,
	};

	virtualDevices = virtualDevices
		.map((vd) => (
			(vd.isDefault === virtualDevice.isDefault
				&& vd.kind === virtualDevice.kind)
				? virtualDevice
				: vd
		))
		.filter((vd) => {
			// In case the device is duplicated as non default, we remove it.
			// It happens on safari because devicechange event is fired
			// before the permission grant event so it first create devices
			// with isDefault=false and then reexecute with isDefault=true.
			if (
				devicesEqual(vd, virtualDevice)
				&& !vd.isDefault
			) return false;
			return true;
		});

	saveLocalStorageValue(virtualDevices);

	return virtualDevice;
};

/**
 * @param {InputDeviceInfo[]} physicalDevices
 * @returns {VirtualDevice[]}
 */
export const updateManyVirtualDevices = (physicalDevices) => {
	physicalDevices.forEach((physicalDevice) => {
		if (physicalDevice.isDefault) {
			updateDefaultVirtualDevice(physicalDevice);
		} else {
			createOrUpdateVirtualDevice(physicalDevice);
		}
	});
	return virtualDevices;
};

/**
 * @param {VirtualDevice['virtualDeviceId']} virtualDeviceId
 * @returns {VirtualDevice | undefined}
 */
export const getVirtualDeviceById = (virtualDeviceId) => (
	virtualDevices.find((vd) => vd.virtualDeviceId === virtualDeviceId)
);

/**
 * @param {MediaDeviceKind} kind
 * @returns {VirtualDevice | undefined}
 */
export const getDefaultVirtualDevice = (kind) => (
	virtualDevices.find((vd) => vd.isDefault && vd.kind === kind)
);
