import { useCallback, useEffect, useRef } from 'react';
import { useHandleFriendRequestChange, useHandleFriendRequestNew } from '../../api-hooks/channel/friends';
import {
	useHandleEventConversationDelete,
	useHandleEventConversationMessageDelete,
	useHandleEventConversationMessageNew,
	useHandleEventConversationMessageUpdate,
	useHandleEventConversationMessageUpdateAll,
	useHandleEventConversationNew,
	useHandleEventConversationUnreadCounts,
	useHandleEventConversationUnreadCountsNew,
	useHandleEventConversationUpdate,
} from '../../api-hooks/conversation';
import {
	connectSockets,
	disconnectSockets,
	joinGlobal,
	onEventBalanceChange,
	onEventConnectionStatus,
	onEventConversationDelete,
	onEventConversationMessageDelete,
	onEventConversationMessageNew,
	onEventConversationMessageUpdate,
	onEventConversationMessageUpdateAll,
	onEventConversationNew,
	onEventConversationUnreadCounts,
	onEventConversationUnreadCountsNew,
	onEventConversationUpdate,
	onEventFriendRequestNew,
	onEventFriendRequestStatusChanged,
	onEventModerateDeactivate,
	onEventNotification,
	onEventNotificationStudioCharge,
	onEventNotificationSystemNew,
	onEventStreamChange,
	onEventStudioActiveStatus,
} from '../../api/ws/global';
import { sessionId } from '../../lib/session';
import { identity, useAuthentication } from '../Authentication/Authentication';
import { usePoints } from '../Membership/Points';
import { useNotification } from '../Notification/Provider';
import { useActiveStudio } from '../Studio/Active/Context';
import { useStatusOverrides } from './StatusOverridesProvider';
import { useStreamSocket } from '../Stream/SocketProvider';
import { useHandleNotificationNew } from '../../api-hooks/notification/notification';

const execIfHandlerExists = (handler, ...args) => {
	if (handler) return handler(...args);
	return undefined;
};

export const GlobalSocketConnector = () => {
	const { isLoggedIn } = useAuthentication();

	const { handleEventNotification, handleEventNotificationStudioCharge } = useNotification();
	const { handleEventConnectionStatus } = useStatusOverrides();
	const { handleEventActiveStudioStatus } = useActiveStudio();

	const handleEventNotificationNew = useHandleNotificationNew();

	const handleEventConversationNew = useHandleEventConversationNew();
	const handleEventConversationDelete = useHandleEventConversationDelete();
	const handleEventConversationUpdate = useHandleEventConversationUpdate();
	const handleEventConversationMessageNew = useHandleEventConversationMessageNew();
	const handleEventConversationMessageDelete = useHandleEventConversationMessageDelete();
	const handleEventConversationMessageUpdateAll = useHandleEventConversationMessageUpdateAll();
	const handleEventConversationMessageUpdate = useHandleEventConversationMessageUpdate();
	const handleEventConversationUnreadCounts = useHandleEventConversationUnreadCounts();
	const handleEventConversationUnreadCountsNew = useHandleEventConversationUnreadCountsNew();

	const { handleEventBalanceChange } = usePoints();
	const handleEventFriendRequestStatusChanged = useHandleFriendRequestChange();
	const handleEventFriendRequestNew = useHandleFriendRequestNew();

	const { handleStreamChange } = useStreamSocket();

	const { logout } = useAuthentication();

	// Use ref to avoid reconnecting the global socket
	const handlersRef = useRef({
		handleEventNotification,
		handleEventNotificationStudioCharge,
		handleEventNotificationNew,
		handleEventConnectionStatus,
		handleEventActiveStudioStatus,
		handleEventConversationNew,
		handleEventConversationDelete,
		handleEventConversationUpdate,
		handleEventConversationMessageNew,
		handleEventConversationMessageDelete,
		handleEventConversationMessageUpdateAll,
		handleEventConversationMessageUpdate,
		handleEventConversationUnreadCounts,
		handleEventConversationUnreadCountsNew,
		handleEventBalanceChange,
		handleEventFriendRequestStatusChanged,
		handleEventFriendRequestNew,
		handleStreamChange,
		logout,
	});

	useEffect(() => {
		handlersRef.current = {
			handleEventNotification,
			handleEventNotificationStudioCharge,
			handleEventNotificationNew,
			handleEventConnectionStatus,
			handleEventActiveStudioStatus,
			handleEventConversationNew,
			handleEventConversationDelete,
			handleEventConversationUpdate,
			handleEventConversationMessageNew,
			handleEventConversationMessageDelete,
			handleEventConversationMessageUpdateAll,
			handleEventConversationMessageUpdate,
			handleEventConversationUnreadCounts,
			handleEventConversationUnreadCountsNew,
			handleEventBalanceChange,
			handleEventFriendRequestStatusChanged,
			handleEventFriendRequestNew,
			handleStreamChange,
			logout,
		};
	}, [
		handleEventNotification,
		handleEventNotificationStudioCharge,
		handleEventConnectionStatus,
		handleEventActiveStudioStatus,
		handleEventConversationNew,
		handleEventConversationDelete,
		handleEventNotificationNew,
		handleEventConversationUpdate,
		handleEventConversationMessageNew,
		handleEventConversationMessageDelete,
		handleEventConversationMessageUpdateAll,
		handleEventConversationMessageUpdate,
		handleEventConversationUnreadCounts,
		handleEventConversationUnreadCountsNew,
		handleEventBalanceChange,
		handleEventFriendRequestStatusChanged,
		handleEventFriendRequestNew,
		handleStreamChange,
		logout,
	]);

	const offAllEvents = useRef([]);
	const leaveGlobal = useRef(() => {});

	const subscribe = useCallback(async () => {
		const { token } = await identity.getAccessToken();

		await connectSockets({ params: { sessionId, token } });

		leaveGlobal.current = joinGlobal();

		offAllEvents.current = [
			onEventNotification((...args) => execIfHandlerExists(
				handlersRef.current.handleEventNotification,
				...args,
			)),
			onEventNotificationStudioCharge((...args) => execIfHandlerExists(
				handlersRef.current.handleEventNotificationStudioCharge,
				...args,
			)),
			onEventNotificationSystemNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventNotificationNew,
				...args,
			)),
			onEventConnectionStatus((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConnectionStatus,
				...args,
			)),
			onEventConversationNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationNew,
				...args,
			)),
			onEventConversationDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationDelete,
				...args,
			)),
			onEventConversationUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationUpdate,
				...args,
			)),
			onEventConversationMessageNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationMessageNew,
				...args,
			)),
			onEventConversationMessageDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationMessageDelete,
				...args,
			)),
			onEventConversationMessageUpdateAll((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationMessageUpdateAll,
				...args,
			)),
			onEventConversationMessageUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationMessageUpdate,
				...args,
			)),
			onEventConversationUnreadCounts((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationUnreadCounts,
				...args,
			)),
			onEventConversationUnreadCountsNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConversationUnreadCountsNew,
				...args,
			)),
			onEventFriendRequestStatusChanged((...args) => execIfHandlerExists(
				handlersRef.current.handleEventFriendRequestStatusChanged,
				...args,
			)),
			onEventFriendRequestNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventFriendRequestNew,
				...args,
			)),
			onEventStudioActiveStatus((...args) => execIfHandlerExists(
				handlersRef.current.handleEventActiveStudioStatus,
				...args,
			)),
			onEventBalanceChange((...args) => execIfHandlerExists(
				handlersRef.current.handleEventBalanceChange,
				...args,
			)),
			onEventModerateDeactivate((...args) => execIfHandlerExists(
				handlersRef.current.logout,
				...args,
			)),
			onEventStreamChange((...args) => execIfHandlerExists(
				handlersRef.current.handleStreamChange,
				...args,
			)),
		];
	}, []);

	const unsubscribe = useCallback(() => {
		offAllEvents.current.forEach((offEvent) => offEvent());
		leaveGlobal.current();

		disconnectSockets();
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			subscribe();
			return unsubscribe;
		}

		return undefined;
	}, [isLoggedIn, subscribe, unsubscribe]);

	return null;
};
