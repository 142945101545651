import PropTypes from 'prop-types';
import { Alert, Col, Modal, Row, Input, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ButtonLoading } from '../../components/Button/Loading';
import { FormLabel } from '../../components/Form/Label';

export const ConfirmationModal = ({
	error,
	errorMessage,
	isOpen,
	loading,
	message,
	onConfirm,
	onDeny,
	requireDelete,
	confirmationText,
}) => {
	const { t } = useTranslation();
	const [deleteText, setDeleteText] = useState('');
	const isConfirmEnabled = !requireDelete || deleteText === confirmationText;

	const handleConfirm = () => {
		if (isConfirmEnabled) {
			onConfirm();
			setDeleteText('');
		}
	};

	const handleDeny = () => {
		setDeleteText('');
		onDeny();
	};

	return (
		<Modal
			centered
			contentClassName="CurrentShow border-0 shadow-lg rounded overflow-hidden bg-transparent m-auto"
			isOpen={isOpen}
			toggle={handleDeny}
			zIndex={2000}
		>
			<Row className="g-0 w-100 bg-dark d-flex justify-content-center align-items-center">
				<Col xs="8">
					<h4 className="text-center pt-5 pb-3 font-weight-bold mt-4">
						{message}
					</h4>
				</Col>
			</Row>
			{requireDelete && (
				<Row className="g-0 w-100 bg-dark d-flex justify-content-center align-items-center">
					<Col xs="8">
						<FormGroup className="mb-3 text-center">
							<FormLabel className="text-center">
								{t('ConfirmationModal.Index.confirmationTextLabel', { confirmationText })}
							</FormLabel>
							<Input
								type="text"
								value={deleteText}
								onChange={(e) => setDeleteText(e.target.value)}
								placeholder={t('ConfirmationModal.Index.enterConfirmationText', { confirmationText })}
								className="text-center"
								autoComplete="off"
							/>
						</FormGroup>
					</Col>
				</Row>
			)}

			{error && (
				<Row className="g-0 w-100 bg-dark d-flex justify-content-center align-items-center">
					<Col xs="8">
						<Alert color="danger">{errorMessage || t('Global.error')}</Alert>
					</Col>
				</Row>
			)}
			<Row className="g-0 w-100 p-3 bg-dark d-flex justify-content-end align-items-center">
				<ButtonLoading
					color="neutral-secondary"
					loading={loading}
					onClick={handleConfirm}
					size="lg"
					disabled={!isConfirmEnabled}
				>
					{t('ConfirmationModal.Index.yes')}
				</ButtonLoading>
				<ButtonLoading
					className="ml-1"
					color="neutral-secondary"
					loading={loading}
					onClick={handleDeny}
					size="lg"
				>
					{t('ConfirmationModal.Index.no')}
				</ButtonLoading>
			</Row>
		</Modal>
	);
};

ConfirmationModal.propTypes = {
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	loading: PropTypes.bool,
	message: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onDeny: PropTypes.func.isRequired,
	requireDelete: PropTypes.bool,
	confirmationText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
	error: false,
	errorMessage: '',
	loading: false,
	message: 'Are you sure?',
	requireDelete: false,
	confirmationText: 'DELETE',
};
