// @ts-check
import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { stopTrack } from './utils';
import { useFilteredSourceParticipantOffers } from '../SourceParticipantOffers/Context';
import { useInputs } from '../Inputs';

/**
 * @import {
 *  SourceParticipantOffer,
 *  SourceParticipantOfferDevice,
 *  SourceParticipantOfferTrack,
 *  SourceParticipantOfferType,
 * } from '../SourceParticipantOffers/Context';
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {{
 *  device: SourceParticipantOfferDevice<T>,
 *  physicalDeviceId: string,
 *  sourceOffer: SourceParticipantOffer<T>,
 * }} DeviceRequest
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {SourceParticipantOfferTrack<T> & {
 * 	physicalDeviceId: string,
 * }} MediaStreamTrackOffer
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {{ status: 'prompt' }
 * 	| { status: 'granted', tracks: MediaStreamTrackOffer<T>[] }
 *  | { status: 'error', error: any }} DeviceRequestStateValue
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {DeviceRequest<T> & DeviceRequestStateValue<T>} DeviceRequestState
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {{
 *  activeTracks: MediaStreamTrackOffer<T>[],
 *	deviceRequestStates: DeviceRequestState<T>[],
 *	requestDevice: <R extends T>(deviceRequest: DeviceRequest<R>) => Promise<void>,
 *	stopDevice: <R extends T>(deviceRequestState: DeviceRequestState<R>) => void,
 * }} UseDeviceRequestsResult
 */

/**
 * @template {SourceParticipantOfferType} T
 * @typedef {(deviceRequest: DeviceRequest<T>) => Promise<MediaStream>} GetMediastream
 */

/*
 * TODO: Make all media share use this hook.
 * It's used only by Audioshare at the moment.
 */

/**
 * Hook to manage device requests
 * @template {SourceParticipantOfferType} T
 * @param {{
 *  disabled?: boolean,
 *  getMediastream: GetMediastream<T>,
 *  sourceParticipantType: T
 * }} param0
 * @returns {UseDeviceRequestsResult<T>}
 */
export const useDeviceRequests = (
	{
		disabled = false,
		getMediastream,
		sourceParticipantType,
	},
) => {
	const { inputDevices } = useInputs();
	const sourceOffers = useFilteredSourceParticipantOffers(sourceParticipantType);
	const [deviceRequestStates, setDeviceRequestStates] = useState(
		/** @type {UseDeviceRequestsResult<T>['deviceRequestStates']} */([]),
	);
	/** @type {UseDeviceRequestsResult<T>['activeTracks']} */
	const activeTracks = useMemo(() => (
		deviceRequestStates
			.filter((drs) => drs.status === 'granted')
			.flatMap((drs) => drs.tracks)
	), [deviceRequestStates]);
	const activeTracksRef = useRef(activeTracks);
	activeTracksRef.current = activeTracks;

	const setDeviceRequestState = useCallback(
		/**
		 * @param {DeviceRequest<T>} deviceRequest
		 * @param {DeviceRequestStateValue<T>} value
		 * @returns {void}
		 */
		(deviceRequest, value) => setDeviceRequestStates((state) => {
			const index = state.findIndex((d) => d.device.deviceId === deviceRequest.device.deviceId);
			const deviceRequestState = {
				...deviceRequest,
				...value,
			};
			if (index === -1) {
				return [
					...state,
					deviceRequestState,
				];
			}
			return state.map((d, i) => (i === index ? deviceRequestState : d));
		}),
		[],
	);

	const removeTrackFromDeviceRequestState = useCallback(
		/**
		 * @param {MediaStreamTrackOffer<T>} track
		 * @returns {void}
		 */
		(track) => setDeviceRequestStates((state) => {
			const index = state.findIndex((drs) => (
				drs.status === 'granted'
				&& drs.tracks.includes(track)
			));
			if (index === -1) return state;
			const drs = /** @type {DeviceRequestState<T> & { status: 'granted' }} */(state[index]);
			return state.map((d, i) => (i === index ? {
				...drs,
				tracks: drs.tracks?.filter((t) => t !== track),
			} : d));
		}),
		[],
	);

	const resetDeviceRequestState = useCallback(
		/**
		 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
		 * @param {DeviceRequest<T>} deviceRequest
		 * @returns {void}
		 */
		(deviceRequest) => setDeviceRequestStates((state) => {
			const index = state.findIndex((d) => d.device.deviceId === deviceRequest.device.deviceId);
			if (index === -1) return state;
			return state.filter((d, i) => i !== index);
		}),
		[],
	);

	const unmountedRef = useRef(false);
	const deviceRequestStatesRef = useRef(deviceRequestStates);
	deviceRequestStatesRef.current = deviceRequestStates;

	const requestDevice = useCallback(
		/** @type {UseDeviceRequestsResult<T>['requestDevice']} */
		async (deviceRequest) => {
			try {
				setDeviceRequestState(deviceRequest, { status: 'prompt' });

				const mediastream = await getMediastream(deviceRequest);

				// Allow cancellation
				const isStillRequested = deviceRequestStatesRef.current.find(
					(d) => d.physicalDeviceId === deviceRequest.physicalDeviceId,
				)?.status === 'prompt';

				// Possible cancellation while getUserMedia was pending
				const tracks = /** @type {MediaStreamTrackOffer<T>[]} */(mediastream.getTracks());
				if (
					!isStillRequested
					|| unmountedRef.current
				) {
					tracks.forEach((track) => track.stop());
					return;
				}

				tracks.forEach((track) => {
					track.device = deviceRequest.device;
					track.physicalDeviceId = deviceRequest.physicalDeviceId;
					track.sourceOffer = deviceRequest.sourceOffer;
				});

				setDeviceRequestState(deviceRequest, { status: 'granted', tracks });
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				setDeviceRequestState(deviceRequest, { status: 'error', error });
			}
		},
		[
			getMediastream,
			setDeviceRequestState,
		],
	);

	useEffect(() => {
		/**
		 * @param {{ target: EventTarget | null }} event
		 * @returns {void}
		 */
		const handleTrackEnded = ({ target: track }) => {
			if (!(track instanceof MediaStreamTrack)) return;

			track.removeEventListener('trackended', handleTrackEnded);
			removeTrackFromDeviceRequestState(/** @type {MediaStreamTrackOffer<T>} */(track));
		};

		activeTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			activeTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [
		activeTracks,
		removeTrackFromDeviceRequestState,
	]);

	const stopDevice = useCallback(
		/** @type {UseDeviceRequestsResult<T>['stopDevice']} */
		(deviceRequestState) => {
			if (deviceRequestState.status === 'granted') {
				const { tracks } = deviceRequestState;
				tracks.forEach(stopTrack);
			}
			resetDeviceRequestState(deviceRequestState);
		},
		[resetDeviceRequestState],
	);

	const sourceOfferDeviceRequests = useMemo(
		() => {
			if (disabled) {
				return [];
			}
			return sourceOffers.reduce((acc, sourceOffer) => {
				sourceOffer.devices.forEach((device) => {
					const physicalDeviceId = inputDevices.find(
						(d) => d.virtualDeviceId === device.deviceId,
					)?.physicalDeviceId;

					if (!physicalDeviceId) return;

					const deviceRequest = {
						device,
						physicalDeviceId,
						sourceOffer,
					};

					acc = [
						...acc,
						deviceRequest,
					];
				});
				return acc;
			}, /** @type {DeviceRequest<T>[]} */([]));
		},
		[
			disabled,
			inputDevices,
			sourceOffers,
		],
	);

	useEffect(() => {
		// stop outdated audioshare
		deviceRequestStates
			.filter((drs) => {
				const { physicalDeviceId } = drs;
				return !sourceOfferDeviceRequests.find(
					(dr) => dr.physicalDeviceId === physicalDeviceId,
				);
			}).forEach((drs) => {
				stopDevice(drs);
			});
		// start not requested yet audioshares
		sourceOfferDeviceRequests
			.filter((dr) => {
				const { physicalDeviceId } = dr;
				return !deviceRequestStates.find(
					(drs) => drs.physicalDeviceId === physicalDeviceId,
				);
			})
			.forEach((deviceRequest) => {
				requestDevice(deviceRequest);
			});
	}, [
		deviceRequestStates,
		requestDevice,
		sourceOfferDeviceRequests,
		stopDevice,
	]);

	// cleanup
	useEffect(() => () => {
		unmountedRef.current = true;
		activeTracksRef.current.forEach(stopTrack);
	}, []);

	const value = useMemo(() => ({
		activeTracks,
		deviceRequestStates,
		requestDevice,
		stopDevice,
	}), [
		activeTracks,
		deviceRequestStates,
		requestDevice,
		stopDevice,
	]);

	return value;
};
