// @ts-check
/* eslint-disable react/prop-types */
import {
	createContext,
	useCallback,
	useContext,
	useMemo,
} from 'react';

import { SourceParticipantOfferType } from '../../SourceParticipantOffers/Context';
import { useDeviceRequests } from '../useDeviceRequests';

/**
 * @import {
 * 	GetMediastream,
 * 	MediaStreamTrackOffer,
 * 	UseDeviceRequestsResult,
 * } from '../useDeviceRequests';
 */

/** @typedef {typeof SourceParticipantOfferType.AUDIOSHARE} AudioshareType */

/**
 * @typedef {MediaStreamTrackOffer<AudioshareType>} MediaStreamTrackAudioshare
 */

/**
 * @typedef {{
 * audioshareActiveTracks: UseDeviceRequestsResult<AudioshareType>['activeTracks'],
 * deviceRequestStates: UseDeviceRequestsResult<AudioshareType>['deviceRequestStates'],
 * requestAudioshare: UseDeviceRequestsResult<AudioshareType>['requestDevice'],
 * stopAudioshare: UseDeviceRequestsResult<AudioshareType>['stopDevice'],
 * }} IMediaShareAudioContext
 */

const MediaShareAudioContext = createContext(/** @type {IMediaShareAudioContext} */({}));

export const useMediaShareAudio = () => useContext(MediaShareAudioContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * 	disabled?: boolean,
 * }} MediaShareAudioProps
 */

export const MediaShareAudio = (
	/** @type {MediaShareAudioProps} */
	{
		children,
		disabled = false,
	},
) => {
	const getMediastream = useCallback(
		/** @type {GetMediastream<AudioshareType>} */
		async (deviceRequest) => {
			const mediastream = await navigator.mediaDevices.getUserMedia({
				video: false,
				audio: {
					deviceId: deviceRequest.physicalDeviceId,
					autoGainControl: false,
					channelCount: 2,
					echoCancellation: false,
					latency: 0,
					noiseSuppression: false,
					sampleRate: 48000,
					sampleSize: 16,
					volume: 1.0,
				},
			});
			return mediastream;
		},
		[],
	);

	const deviceRequests = useDeviceRequests({
		disabled,
		getMediastream,
		sourceParticipantType: SourceParticipantOfferType.AUDIOSHARE,
	});

	const value = useMemo(() => ({
		audioshareActiveTracks: deviceRequests.activeTracks,
		deviceRequestStates: deviceRequests.deviceRequestStates,
		requestAudioshare: deviceRequests.requestDevice,
		stopAudioshare: deviceRequests.stopDevice,
	}), [deviceRequests]);

	return (
		<MediaShareAudioContext.Provider value={value}>
			{children}
		</MediaShareAudioContext.Provider>
	);
};
