// @ts-check
import { createContext, useContext } from 'react';

/**
 * @import { StudioSource } from '../../../../../webrtc-router/src/lib/mixer/Formatter';
 */

/**
 * @typedef {{
 *  id: StudioSource['id'],
 * 	type: StudioSource['type'],
 * }} StudioSourceIdentifier
 */

/**
 * @typedef {{
 * 	id: StudioSource['id'],
 *  type: StudioSource['type'],
 *  settings: {
 * 		monitor?: boolean,
 *  },
 * }} StudioSourceSettings
 */
/**
 * @typedef {{
 *  getSettingsBySource: (source: StudioSourceIdentifier) => StudioSourceSettings | undefined,
 *  sourceSettings: StudioSourceSettings[],
 *  updateSettingsBySource: (
 * 		source: StudioSourceIdentifier,
 * 		settings: Partial<StudioSourceSettings['settings']>,
 * 	) => void,
 * }} IStudioSourceSettingsContext
 */

export const StudioSourceSettingsContext = createContext(
	/** @type {IStudioSourceSettingsContext | undefined} */(undefined),
);

/**
 * @returns {IStudioSourceSettingsContext}
 */
export const useStudioSourceSettings = () => {
	const studioSourcesContext = useContext(StudioSourceSettingsContext);
	if (!studioSourcesContext) {
		throw new Error('useStudioSources must be used within a StudioSourceSettingsProvider');
	}
	return studioSourcesContext;
};
