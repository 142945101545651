/* eslint-disable react/prop-types */
// @ts-check

import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input } from 'reactstrap';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { FaSearch } from 'react-icons/fa';
import { FormLabel } from '../../Form/Label';
import { SelectUsersMulti } from '../../SelectUsersMulti/SelectUsersMulti';
import { FormPhone } from '../../Form/Phone';
import { ShareRecipientType } from '../Share.helper';

/**
 * @import { SelectUsersMultiSuggestion } from '../../SelectUsersMulti/SelectUsersMulti';
 */

/**
 * @param {string} value
 * @returns {boolean}
 */
const isEmailInput = (value) => typeof value === 'string' && /@/.test(value);

/**
 * @param {string} value
 * @returns {boolean}
 */
const isPhoneInput = (value) => {
	if (!value) return false;

	if (isPossiblePhoneNumber(value)) return true;

	const phoneNumberPattern = /^[+]?[(]?[0-9]{2}[)]?[-\s.]?[0-9]{2}[-\s.]?[0-9]{3,}$/;
	if (phoneNumberPattern.test(value)) return true;

	const startsWithPlusOrParenthesis = /^[+(]/;
	if (startsWithPlusOrParenthesis.test(value)) return true;

	return false;
};

/**
 * @typedef {{
 *   users: SelectUsersMultiSuggestion[],
 *   message: string
 * }} FormData
 */

/**
 * @typedef {{
 *  searchLabel: string,
 *  alreadySharedUsersIds: string[],
 *  inputColor: "dark" | "dark-border" | "light" | "light-border" | undefined,
 *  isLoading: boolean,
 *  studio:{
 *   owner:{
 * 	  _id:string,
 *   }
 *  },
 *  suggestions:[],
 *  setSuggestionsSearchValue: (arg0: string)=> void,
 *  handleChange: (param: {
 *    type: string,
 *    value: string | SelectUsersMultiSuggestion[],
 *  }) => void,
 *  selectedRole: string,
 *  setCurrentInput: (arg0: string) => void,
 *  currentInput: string,
 *  formData: FormData,
 *  setFormData: (value: import('react').SetStateAction<FormData>) => void
 * }} UnifiedShareInputProps
 */

export const UnifiedShareInput = (
/** @type {UnifiedShareInputProps} */
	{
		searchLabel,
		alreadySharedUsersIds,
		inputColor,
		isLoading,
		studio,
		suggestions,
		setSuggestionsSearchValue,
		handleChange,
		setCurrentInput,
		currentInput,
		formData,
		setFormData,
	},
) => {
	const { t } = useTranslation();
	const [inputType, setInputType] = useState(ShareRecipientType.USERS);

	const previousInputType = useRef(ShareRecipientType.USERS);

	/**
   * @param {string} value
   * @returns {ShareRecipientType}
   */
	const determineInputType = (value) => {
		if (!value) return ShareRecipientType.USERS;
		if (isPhoneInput(value)) return ShareRecipientType.SMS;
		if (isEmailInput(value)) return ShareRecipientType.EMAIL;
		return ShareRecipientType.USERS;
	};

	const handleEmailAndPhoneInputChange = useCallback(
		/** @param {string} value */
		(value) => {
			setCurrentInput(value);

			previousInputType.current = inputType;

			const newInputType = determineInputType(value);
			setInputType(newInputType);

			if (newInputType === ShareRecipientType.USERS) {
				setSuggestionsSearchValue?.(value);
			}
		}, [inputType, setCurrentInput, setSuggestionsSearchValue],
	);

	const handleKeyDown = useCallback(
		/** @param {import('react').KeyboardEvent<HTMLInputElement>} e */
		(e) => {
			const { value } = /** @type {HTMLInputElement} */ (e.target);

			if (!value) return;

			const newType = determineInputType(value);

			if (newType !== ShareRecipientType.USERS) {
				setInputType(newType);
				setCurrentInput(value);
			}
		},
		[setCurrentInput],
	);

	const handleEmailInviteChange = useCallback(
		/** @param {React.ChangeEvent<HTMLInputElement>} e */
		(e) => {
			const { value } = e.target;
			handleEmailAndPhoneInputChange(value);
			handleChange({ type: ShareRecipientType.EMAIL, value });
		}, [handleChange, handleEmailAndPhoneInputChange],
	);

	const handleSmsInviteChange = useCallback(
		/** @param {string} value */
		(value) => {
			if (!value) {
				setInputType(ShareRecipientType.USERS);
				setCurrentInput('');
				setSuggestionsSearchValue?.('');
				handleChange({ type: ShareRecipientType.USERS, value: '' });
				return;
			}
			setCurrentInput(value);
			handleChange({ type: ShareRecipientType.SMS, value });
		}, [handleChange, setCurrentInput, setSuggestionsSearchValue],
	);

	const shouldAutoFocus = inputType === ShareRecipientType.USERS
	&& (previousInputType.current === ShareRecipientType.EMAIL
	|| previousInputType.current === ShareRecipientType.SMS);

	const renderInput = () => {
		switch (inputType) {
		case ShareRecipientType.EMAIL:
			return (
				<div
					className={`UnifiedShareInput pl-2 bg-${inputColor} content-${inputColor} react-select-${inputColor}__control d-flex align-items-center`}
				>
					<FaSearch
						className="text-secondary"
						size={16}
						style={{ marginRight: '7px', height: '38px' }}
					/>
					<Input
						type="email"
						autoFocus
						value={currentInput}
						className="border-0 p-0 bg-transparent shadow-none"
						onChange={handleEmailInviteChange}
						onKeyDown={handleKeyDown}
					/>
				</div>
			);

		case ShareRecipientType.SMS:
			return (
				<FormPhone
					value={currentInput}
					autoFocus
					style={{ height: '38px' }}
					className="UnifiedShareInput border-0 pl-2"
					onChange={handleSmsInviteChange}
					placeholder={t('Share.Tab.Sms.enterPhoneNumber')}
					onInputChange={handleEmailAndPhoneInputChange}
				/>
			);

		case ShareRecipientType.USERS:
		default:
			return (
				<SelectUsersMulti
					alreadySelectedUsersIds={alreadySharedUsersIds}
					color={inputColor}
					isLoading={isLoading}
					defaultInputValue={currentInput}
					autoFocus={shouldAutoFocus}
					name="users"
					placeholder={searchLabel}
					propSuggestions={suggestions}
					setPropSuggestionsSearchValue={setSuggestionsSearchValue}
					setUsers={(users) => {
						setFormData({ ...formData, users });
						handleChange({ type: ShareRecipientType.USERS, value: users });
					}}
					suggestionsFilter={(sugg) => sugg.id !== studio?.owner?._id}
					users={formData.users}
					onKeyDown={handleKeyDown}
				/>
			);
		}
	};

	return (
		<FormGroup>
			<FormLabel>{t('Share.sendTo')}</FormLabel>
			{renderInput()}
		</FormGroup>
	);
};
