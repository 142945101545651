export const SOURCE_TYPE_DEFAULT = 'default';
export const SOURCE_TYPE_AD = 'ad';
export const SOURCE_TYPE_IMAGE = 'image';
export const SOURCE_TYPE_GFX = 'gfx';
export const SOURCE_TYPE_GFX_VIDEO = 'gfxvideo';
export const SOURCE_TYPE_PARTICIPANT = 'participant';
export const SOURCE_TYPE_PLAYLIST = 'playlist';
export const SOURCE_TYPE_STREAM = 'stream';
export const SOURCE_TYPE_TEXT = 'text';
export const SOURCE_TYPE_VIDEO = 'video';

export const STREAM_TYPE_LIVE = 'live';
export const STREAM_TYPE_PREVIEW = 'participant';
