// @ts-check

import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { createCanvasAndContext, getBlob } from '../../lib/canvas';

export const ScreenshotProvider = createContext({});
export const useScreenshot = () => useContext(ScreenshotProvider);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} PlayerScreenshotProviderProps
 */

export const PlayerScreenshotProvider = (
	/** @type {PlayerScreenshotProviderProps} */
	{ children },
) => {
	const playerRef = useRef(/** @type {HTMLVideoElement|undefined} */(undefined));

	const screenShot = useCallback(async () => {
		if (!playerRef.current) throw new Error('PlayerScreenshotProvider: playerRef.current is not defined');

		const {
			canvas,
			context,
		} = createCanvasAndContext({
			offscreen: true,
			width: playerRef.current.videoWidth,
			height: playerRef.current.videoHeight,
		});

		context.drawImage(playerRef.current, 0, 0, canvas.width, canvas.height);

		const blob = await getBlob(canvas, { type: 'image/png' });

		if (!blob) {
			throw new Error('Screenshot failed');
		}

		const objectURL = URL.createObjectURL(blob);
		const screenshotImage = new Image();
		screenshotImage.src = objectURL;

		return {
			file: new File([blob], 'filename'),
			blob,
			filename: screenshotImage.src,
			width: canvas.width,
			height: canvas.height,
			label: 'Screenshot studio',
		};
	}, []);

	const setPlayerRef = useCallback(
		/**
		 * @param {HTMLVideoElement} ref
		 * @returns {void}
		 */
		(ref) => { playerRef.current = ref; },
		[],
	);

	const contextValue = useMemo(() => ({
		screenShot,
		setPlayerRef,
	}), [
		screenShot,
		setPlayerRef,
	]);

	return (
		<ScreenshotProvider.Provider value={contextValue}>
			{children}
		</ScreenshotProvider.Provider>
	);
};

PlayerScreenshotProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
