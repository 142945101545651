// @ts-check

import { createContext, useContext, useMemo } from 'react';

/** @enum {typeof SourceParticipantOfferType[keyof typeof SourceParticipantOfferType]} */
export const SourceParticipantOfferType = /** @type {const} */({
	CONFIG: 'config',
	AUDIOSHARE: 'audio',
	IMAGESHARE: 'image',
	VIDEOSHARE: 'video',
	SCREENSHARE: 'screen',
});

/**
 * @typedef {'audioinput' | 'videoinput'} SourceParticipantOfferMediaDeviceKind
 * @typedef {'audioscreen' | 'videoscreen'} SourceParticipantOfferScreenDeviceKind
 * @typedef {'audiofile' | 'videofile'} SourceParticipantOfferFileDeviceKind
 * @typedef {SourceParticipantOfferMediaDeviceKind
 * | SourceParticipantOfferScreenDeviceKind
 * | SourceParticipantOfferFileDeviceKind} SourceParticipantOfferDeviceKind
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {T extends typeof SourceParticipantOfferType.CONFIG
 * 	? SourceParticipantOfferMediaDeviceKind
 * 	: T extends typeof SourceParticipantOfferType.SCREENSHARE
 * 		? SourceParticipantOfferScreenDeviceKind
 * 		: T extends (
 * 			typeof SourceParticipantOfferType.IMAGESHARE
 * 			| typeof SourceParticipantOfferType.VIDEOSHARE
 * 		)
 * 			? SourceParticipantOfferFileDeviceKind
 * 			: SourceParticipantOfferDeviceKind} SourceParticipantOfferDeviceKindFromType
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {{
 *  deviceId: string,
 *  disabled?: boolean,
 *  kind: SourceParticipantOfferDeviceKindFromType<T>,
 *  label: string,
 * }} SourceParticipantOfferDevice;
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {MediaStreamTrack & {
 *  configId?: number,
 *  device: SourceParticipantOfferDevice<T>,
 *  sourceOffer: {
 * 		id: SourceParticipantOffer['id'],
 *  	subType: SourceParticipantOffer['subType'],
 *  },
 * }} SourceParticipantOfferTrack
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {{
 *  computerId: string,
 *  configId?: number,
 *  id: string,
 *  label: string,
 *  subType: T,
 *  devices: SourceParticipantOfferDevice<T>[],
 * }} SourceParticipantOffer
 */

/**
 * @template {SourceParticipantOfferType} [T=SourceParticipantOfferType]
 * @typedef {T extends SourceParticipantOfferType
 * ? SourceParticipantOffer<T>
 * : never} SourceParticipantOfferTypes
 */

/**
 * @typedef {{
 *  createOrUpdateSource: <T extends SourceParticipantOfferType>(
 * 		source: SourceParticipantOffer<T>) => void
 *  removeSourceById: (sourceId: string) => void
 *  sources: SourceParticipantOffer[],
 * }} ISourceParticipantOffersContext
 */

export const SourceParticipantOffersContext = createContext(
	/** @type {ISourceParticipantOffersContext | undefined} */(undefined),
);

export const useSourceParticipantOffers = () => {
	const sourcesContext = useContext(SourceParticipantOffersContext);
	// type guard (removes undefined type)
	if (!sourcesContext) {
		throw new Error('useSourceParticipantOffers must be used within a SourceParticipantOffers');
	}
	return sourcesContext;
};

/**
 * @template {SourceParticipantOfferType} T
 * @param {T} subType
 * @returns {(source: SourceParticipantOffer) => source is SourceParticipantOffer<T>}
 */
export const getTypeFilter = (subType) => (
	/** @type {(source: SourceParticipantOffer) => source is SourceParticipantOffer<T>}*/(
		(source) => source.subType === subType
	)
);

/**
 * @template {SourceParticipantOfferType} T
 * @param {T} sourceParticipantType
 * @returns {SourceParticipantOffer<T>[]}
 */
export const useFilteredSourceParticipantOffers = (sourceParticipantType) => {
	const { sources } = useSourceParticipantOffers();
	return useMemo(
		() => sources.filter(
			/** @type {(source: SourceParticipantOffer) => source is SourceParticipantOffer<T>} */(
				(s) => s.subType === sourceParticipantType),
		),
		[sourceParticipantType, sources],
	);
};
