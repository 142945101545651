// @ts-check

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useConst } from '../../../lib/hooks';

import { createCanvasAndContext } from '../../../lib/canvas';
import { useMediaCrop } from './Provider';

/**
 * @import { MediaStreamTrackCrop, PersonalCrop } from './Provider';
 * @import { MediaStreamTrackUser } from '../User';
 */

/**
 * @typedef {ReturnType<typeof createCanvasAndContext>} CanvasAndContext
 * @typedef {{
 *   size: { width: number, height: number }
 * }} Size
 * @typedef {CanvasAndContext & Size} CanvasAndContextSize
 */

/** @type {CanvasAndContextSize | undefined} */
let canvasAndContext;

/**
 * @typedef {{
 * 	x: number,
 * 	y: number,
 * 	width: number,
 * 	height: number,
 * }} Crop
 */

/**
 * @param {number} zoomFactor
 * @param {number} width
 * @param {number} height
 * @returns {Crop | undefined}
 */
const calculateZoomCoordinates = (zoomFactor, width, height) => {
	if (!width || !height) return undefined;

	const canvasCenter = {
		x: width / 2,
		y: height / 2,
	};

	const zoomedWidth = width / zoomFactor;
	const zoomedHeight = height / zoomFactor;

	const offsetX = (width - zoomedWidth) * (canvasCenter.x / width);
	const offsetY = (height - zoomedHeight) * (canvasCenter.y / height);

	const topLeft = {
		x: offsetX,
		y: offsetY,
	};

	const topRight = {
		x: offsetX + zoomedWidth,
		y: offsetY,
	};

	const bottomLeft = {
		x: offsetX,
		y: offsetY + zoomedHeight,
	};

	return {
		x: topLeft.x,
		y: topLeft.y,
		width: topRight.x - topLeft.x,
		height: bottomLeft.y - topLeft.y,
	};
};

/**
 * @param {MediaStreamTrackUser | undefined} userVideoTrack
 * @param {PersonalCrop | undefined} personalCrop
 */
export const useMediaCropPerformer = (userVideoTrack, personalCrop) => {
	const source = useConst(() => document.createElement('video'));
	const id = useRef(/** @type {number|undefined} */(undefined));
	const [cropVideoTrack, setCropVideoTrack] = useState(
		/** @type {MediaStreamTrackCrop|undefined} */(undefined),
	);
	const { setPersonalCrop } = useMediaCrop();
	const cropRef = useRef(/** @type {Crop | undefined} */(undefined));

	useEffect(() => {
		const handleOrientationChange = () => {
			setPersonalCrop(undefined);
		};
		window.addEventListener('orientationchange', handleOrientationChange);
		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
		};
	}, [personalCrop, setPersonalCrop]);

	const perform = useCallback(() => {
		if (
			!cropRef.current
			|| !userVideoTrack
			|| !canvasAndContext
		) return;

		const ctx = canvasAndContext.context;

		ctx.clearRect(
			0,
			0,
			canvasAndContext.size.width,
			canvasAndContext.size.height,
		);
		ctx.drawImage(
			source,
			cropRef.current.x,
			cropRef.current.y,
			cropRef.current.width,
			cropRef.current.height,
			0,
			0,
			canvasAndContext.size.width,
			canvasAndContext.size.height,
		);

		id.current = window.requestAnimationFrame(perform);
	}, [
		source,
		userVideoTrack,
	]);

	const cropTrackRef = useRef(/** @type {MediaStreamTrackCrop | undefined}*/(undefined));

	const cleanup = useCallback(() => {
		if (id.current) cancelAnimationFrame(id.current);
		if (source) {
			source.pause();
			source.srcObject = null;
			source.oncanplay = null;
		}
		if (cropTrackRef.current) {
			cropTrackRef.current.stop();
		}
		canvasAndContext = undefined;
		cropRef.current = undefined;
	}, [source]);

	useEffect(() => {
		if (personalCrop && userVideoTrack) {
			const videotrack = new MediaStream([userVideoTrack]);
			source.setAttribute('playsinline', '');
			source.autoplay = true;
			source.muted = true;
			source.srcObject = videotrack;
			source.oncanplay = () => {
				source.play();
				perform();
			};

			if (!canvasAndContext) {
				const { width, height } = userVideoTrack.getSettings();
				canvasAndContext = /** @type {CanvasAndContextSize} */(createCanvasAndContext({
					offscreen: false,
					width,
					height,
				}));
				canvasAndContext.size = { width: width || 0, height: height || 0 };

				const tracks = /** @type {[MediaStreamTrackCrop]}*/(
				/** @type {HTMLCanvasElement} */(canvasAndContext.canvas).captureStream(30).getVideoTracks()
				);
				const cropTrack = tracks[0];
				cropTrack.configId = userVideoTrack.configId;
				cropTrack.device = userVideoTrack.device;
				cropTrack.physicalDeviceId = userVideoTrack.physicalDeviceId;
				cropTrack.sourceOffer = userVideoTrack.sourceOffer;
				cropTrack.isCrop = true;
				cropTrackRef.current = cropTrack;
				setCropVideoTrack(cropTrack);
			}

			cropRef.current = calculateZoomCoordinates(
				personalCrop.factor,
				canvasAndContext.canvas.width || 0,
				canvasAndContext.canvas.height || 0,
			);
		} else {
			cleanup();
			setCropVideoTrack(undefined);
			setPersonalCrop(undefined);
		}
		return () => {
			if (id.current) cancelAnimationFrame(id.current);
		};
	}, [
		cleanup,
		perform,
		personalCrop,
		setPersonalCrop,
		source,
		userVideoTrack,
	]);

	const cleanupRef = useRef(cleanup);
	cleanupRef.current = cleanup;
	useEffect(() => () => {
		cleanupRef.current();
	}, []);

	return useMemo(
		() => ({
			cropVideoTrack,
		}),
		[
			cropVideoTrack,
		],
	);
};
